import {onPageLoad, onPageSwap, onPageLoadAndSwap, swup, user_data, updateUserData} from '@/front/init-swup';
import { validate, validationRequired, validationEmail, resetValidationMessages, showValidationMessage} from '@/front/validate';
import {translate} from "../translate";

onPageLoadAndSwap(function($) {
    $('#donor-information').submit(function(event) {
        let form = $(this);
        let valid = true;
        event.preventDefault();

        form.find('.general-error').addClass('hidden');
        form.find('.general-success').addClass('hidden');

        resetValidationMessages();
        valid = validate(form.find('#firstname'), validationRequired, translate('firstname-required')) && valid;
        valid = validate(form.find('#lastname'), validationRequired, translate('lastname-required')) && valid;
        valid = validate(form.find('#street'), validationRequired, translate('street-required')) && valid;
        valid = validate(form.find('#zipcode'), validationRequired, translate('zipcode-required')) && valid;
        valid = validate(form.find('#city'), validationRequired, translate('city-required')) && valid;
        valid = validate(form.find('#country'), validationRequired, translate('country-required')) && valid;
        valid = validate(form.find('#phone'), validationRequired, translate('phone-required')) && valid;
        valid = validate(form.find('#email'), validationRequired, translate('email-required')) &&
                validate(form.find('#email'), validationEmail, translate('email-not-valid')) && valid;

        if(!valid) {
            showValidationMessage();
            return false;
        }

        $.post('/user/my-info.json', {
            _token: user_data.csrf,
            title: form.find('input[name=title]:checked').val(),
            firstname: form.find('#firstname').val(),
            insertion: form.find('#insertion').val(),
            lastname: form.find('#lastname').val(),
            street: form.find('#street').val(),
            address_line1: form.find('#address_line1').val(),
            zipcode: form.find('#zipcode').val(),
            city: form.find('#city').val(),
            country_iso: form.find('#country').val(),
            state_code: form.find('#state').val(),
            phone: form.find('#phone').val(),
            email: form.find('#email').val()
        }, function(data) {
            if(typeof data.id != 'undefined') {
                updateUserData(data);
                form.find('.general-success').removeClass('hidden');
            }
            else {
                form.find('.general-error').html(data.message);
                form.find('.general-error').removeClass('hidden');
            }
        }, 'json');


    })
});
