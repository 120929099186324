import {onPageLoad, onPageSwap, onPageLoadAndSwap, swup, user_data} from '@/front/init-swup';
import {resetValidationMessages, showValidationMessage, validate, validationRequired} from "@/front/validate";
import {translate} from "../translate";

onPageLoadAndSwap(function($) {
    $('#change-password').submit(function(event) {
        let $form = $(this);
        let valid = true;
        event.preventDefault();

        $form.find('.general-error').addClass('hidden');
        $form.find('.general-success').addClass('hidden');

        resetValidationMessages();

        valid = validate($form.find('#current_password'), validationRequired, translate('current-password-required')) && valid;
        valid =
                validate($form.find('#new_password'), validationRequired, translate('password-required')) &&
                validate($form.find('#new_password'), function(value) {
                        return value.length >= 8 && value.length <= 20;
                    }, translate('password-length')) &&
                validate($form.find('#new_password'), function(value) {
                        return /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value) && /[^0-9a-zA-Z ]/.test(value);
                    }, translate('password-complexity')) &&
            valid;
        valid = validate($form.find('#repeat_password'), validationRequired, translate('password-not-repeated')) &&
                validate($form.find('#repeat_password'), function(value) {
                        return $form.find('#repeat_password').val() == $form.find('#new_password').val();
                    }, translate('password-not-equal')) &&
            valid;

        if(!valid) {
            showValidationMessage()
            return;
        }

        $.post('/user/change-password.json', {
            _token: user_data.csrf,
            current: $form.find('#current_password').val(),
            new: $form.find('#new_password').val(),
            repeat: $form.find('#repeat_password').val()
        }, function(data) {
            if(data.success) {
                $form.find('.general-success').removeClass('hidden');
            }
            else {
                $form.find('.general-error').html(data.message);
                $form.find('.general-error').removeClass('hidden');
            }

            $form.find('#current_password').val('');
            $form.find('#new_password').val('');
            $form.find('#repeat_password').val('');
        }, 'json');
    });
});
