import {onPageLoad} from "@/front/init-swup";
import $ from "jquery";

let i18n = [];

onPageLoad(function($) {
    $.get('/translate.json', function(data) {
        i18n = data;
    });
});

function translate(text) {
    let lang = languageIso();
    if(typeof(i18n[text]) == 'undefined') return text;
    if(typeof(i18n[text][lang]) == 'undefined' && typeof(i18n[text].en) !== 'undefined') return i18n[text].en;
    if(typeof(i18n[text][lang]) == 'undefined') return text;
    if(i18n[text][lang].length == 0 && typeof(i18n[text].en) !== 'undefined') return i18n[text].en;

    return i18n[text][lang];
}

function languageIso() {
    return $('html').attr('lang');
}

export {translate, languageIso}
