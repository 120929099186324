function disableButton($button) {
    $button.addClass('opacity-50');
    $button.find('svg').removeClass('hidden');

    $button.attr('disabled', true);
}


function enableButton($button) {
    $button.removeClass('opacity-50');
    $button.find('svg').addClass('hidden');

    $button.attr('disabled', false);
}

export { disableButton, enableButton };
