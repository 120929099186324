import {onPageLoadAndSwap, user_data} from "@/front/init-swup";
import {
    resetValidationMessages,
    showValidationMessage,
    validate,
    validationEmail,
    validationRequired
} from "@/front/validate";
import {translate} from "../translate";


onPageLoadAndSwap(function($) {

    $('#register-form').submit(function(event) {
        event.preventDefault();

        let $form = $(this);
        let valid = true;

        resetValidationMessages();

        valid = validate($form.find('#email'), validationRequired, translate('enter-email')) &&
                validate($form.find('#email'), validationEmail, translate('enter-valid-email')) &&
                valid;

        if(!valid) {
            showValidationMessage();
            return;
        }

        $.post('/user/register.json', {
            _token: user_data.csrf,
            email: $form.find('#email').val()
        }, function() {}, 'json');

        $form.hide();
        $('#register-form-send').removeClass('hidden');

    });

})
