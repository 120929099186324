import {onPageLoad} from "@/front/init-swup";
import $ from "jquery";

function showModal(modalId) {
    $(modalId).removeClass('hidden');
    $('body').addClass('no-scroll');
}

function hideModal(modalId) {
    $(modalId).addClass('hidden');
    $('body').removeClass('no-scroll');
}

onPageLoad(function ($) {

    $('body').on('click', '[data-modal]', function () {
        let modalId = $(this).data('modal');

        showModal(modalId);
    });

    $('body').on('click', '.modal-backdrop', function () {
        let id = $(this).closest('.modal').attr('id');

        hideModal('#' + id);
    });

    $('body').on('click', '.close-modal', function () {
        let id = $(this).closest('.modal').attr('id');
        hideModal('#' + id);
    });

});

export {showModal, hideModal}
