import {onPageLoad, onPageSwap} from "@/front/init-swup";

onPageLoad(function($) {
    let selector = 'span[role=switch]';

    function setToggle(button, value) {
        let bullet = button.find('span');
        let input = button.find('input');

        if(value) {
            button.addClass('on');

            button.addClass('bg-primary-600');
            bullet.addClass('translate-x-5');
            bullet.addClass('rtl:-translate-x-5');

            button.removeClass('bg-gray-200');
            bullet.removeClass('translate-x-0');

            input.attr('checked', true);
            input.trigger('change');
        }
        else {
            button.removeClass('on');

            button.removeClass('bg-primary-600');
            bullet.removeClass('translate-x-5');
            bullet.removeClass('rtl:-translate-x-5');

            button.addClass('bg-gray-200');
            bullet.addClass('translate-x-0');

            input.attr('checked', false);
            input.trigger('change');
        }
    }

    function toggleSelected(toggle) {
        return toggle.find('input').is(':checked');
    }

    function init() {
        $(selector).each(function() {
            let toggle = $(this);
            setToggle(toggle, toggleSelected(toggle));
        })
    }

    $('body').on('click', selector, function() {
        let button = $(this);
        setToggle(button, !toggleSelected(button));
    });

    onPageSwap(init);
    init();
});
