import {onPageLoad, onPageSwap, onPageLoadAndSwap, swup, user_data} from '@/front/init-swup';
import {resetValidationMessages, showValidationMessage, validationRequired, validationEmail, validate} from "@/front/validate";
import {getCaptchaToken} from "@/3rdparty/google/recaptcha3";
import {languageIso, translate} from "@/front/translate";

onPageLoadAndSwap(function($) {
    $('#contact-form').submit(async function(event) {
        let $form = $(this);
        let valid = true;
        event.preventDefault();

        $form.find('.general-error').addClass('hidden');

        resetValidationMessages();

        valid = validate($form.find('#name'), validationRequired, translate('name-required')) && valid;
        valid = validate($form.find('#email'), validationRequired, translate('email-required')) &&
                validate($form.find('#email'), validationEmail, translate('email-not-valid')) && valid;
        valid = validate($form.find('#subject'), validationRequired, translate('subject-required')) && valid;
        valid = validate($form.find('#remarks'), validationRequired, translate('remarks-required')) && valid;

        if(!valid) {
            showValidationMessage();
            return false;
        }

        let captchav3 = await getCaptchaToken('contact');

        $.post('/forms/contact.json', {
            _token: user_data.csrf,
            _captchav3: captchav3,
            name: $form.find('#name').val(),
            email: $form.find('#email').val(),
            subject: $form.find('#subject').val(),
            remarks: $form.find('#remarks').val()
        }, function(data) {
            if(data.success) {
                $form.hide();
                $('#contact-form-success').show();
            }
            else {
                $form.find('.general-error').html(data.message);
                $form.find('.general-error').removeClass('hidden');
            }
        }, 'json');


    })
});
