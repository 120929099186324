import $ from "jquery";
import {onPageLoadAndSwap} from "@/front/init-swup";


let years= [];



function setYearsInSelect(years) {


    years.forEach((year) => $('#from_year_select').append(`<option>${year}</option>`));
    years.forEach((year) => $('#to_year_select').append(`<option>${year}</option>`));



}


onPageLoadAndSwap(function() {
    if( $('#from_year_select').is('#from_year_select')) {
        if(years.length === 0) {
            $.get('/user/donation-years.json', function(data) {
                years = data.years;
                setYearsInSelect(years)
            },'json')
        }
        else {
            setYearsInSelect(years);
        }

        $('#yearly_report_popup .btn-ok').click(function(e) {
            let fromYear = $('#from_year_select').val();
            let toYear = $('#to_year_select').val();
            document.location.href = '/docs/my-yearly-report?from=' + fromYear + '&to=' + toYear;
        })

    }

});

