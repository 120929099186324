import Bugsnag from '@bugsnag/js'

Bugsnag.start('26042cb20f0e11fde767b12231239132');

import "jquery";
import "swup";

import './front/init-swup';
import './front/translate';
import './front/bluesnap';
import './3rdparty/google/recaptcha3';
import './front/yearly-report';
import './front/check-activity';
import './front/usTaxpayer';

import './front/components/toggle';
import './front/components/nav';
import './front/components/modal';
import './front/components/state';

import './front/forms/pre-load-data';
import './front/forms/contact';
import './front/forms/donate';
import './front/forms/login';
import './front/forms/donor-information';
import './front/forms/change-password';
import './front/forms/forgot-password';
import './front/forms/register';
import './front/forms/register-account';
import './front/forms/reset-password';

import './front/components/scroll-to-anchor'
import './front/components/scroll-to-top-btn'

// if(import.meta.env.VITE_ENV == 'local') {
//
//     let fillForm;
//     fillForm = await;
// }

