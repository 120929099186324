import {onPageLoadAndSwap} from "@/front/init-swup";

onPageLoadAndSwap(function ($) {
    // Get the button element
    var scrollToTopBtn = $("#scroll-to-top-btn");

    // Initialize the button state
    updateButtonState();

    // Listen for the scroll event on the window object
    $(window).scroll(debounce(function () {
        updateButtonState();
    }, 100));

    // Listen for clicks on the button
    scrollToTopBtn.click(function () {
        // Scroll back to the top of the page
        $("html, body").animate({scrollTop: 0}, "slow");
    });

    function updateButtonState() {
        // Get the current y-offset (how far the user has scrolled down)
        var yOffset = $(window).scrollTop();

        // Show the button if the user has scrolled down more than 200 pixels
        if (yOffset > 200) {
            if (scrollToTopBtn.hasClass("hidden") || scrollToTopBtn.hasClass("fade-out")) {
                scrollToTopBtn.removeClass("hidden fade-out").addClass("block fade-in");
            }
        } else {
            if (scrollToTopBtn.hasClass("block") || scrollToTopBtn.hasClass("fade-in")) {
                scrollToTopBtn.addClass("fade-out").removeClass("block fade-in");
                setTimeout(function () {
                    scrollToTopBtn.addClass("hidden").removeClass("fade-out");
                }, 300); // Wait for the fade-out animation to finish before hiding the button
            }
        }
    }

    // Debounce function to limit the frequency of updates to the button state
    function debounce(func, delay) {
        var timer;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(func, delay);
        }
    }
});
