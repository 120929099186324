import {onPageLoad} from "@/front/init-swup";


onPageLoad(function ($) {

    $(document).on('click', 'a[href*="#"]', function (e) {
        let $link = $(this);
        // Prevent default click behavior

        // Get the ID of the target element
        const targetId = this.hash.slice(1);

        // Get the target element
        const target = $('#' + targetId);

        if (target.length) {
            // Calculate the distance from the top of the page to the target element
            const targetPosition = target.offset().top - 20;

            // Calculate the distance to scroll
            const distance = targetPosition - $(window).scrollTop();

            // Animate the scroll position
            $('html, body').animate({
                scrollTop: targetPosition
            }, 'slow', function () {
                // Highlight the target element for a couple of seconds
                if ($($link).is('.no-flash')) {

                } else {
                    target.addClass('animate-highlight');
                    setTimeout(function () {
                        target.removeClass('animate-highlight');
                    }, 2000);
                }
            });
        }
    });
});

