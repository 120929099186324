import {onPageLoadAndSwap, user_data} from "@/front/init-swup";
import {addError, resetValidationMessages, showValidationMessage, validate, validationRequired} from "@/front/validate";
import {translate} from "../translate";

onPageLoadAndSwap(function($) {

    $('#register-account').submit(function(event) {
        event.preventDefault();

        let $form = $(this);
        let valid = true;

        resetValidationMessages();

        valid = validate($form.find('#username'), validationRequired, translate('enter-username')) &&
                validate($form.find('#username'), function(value) {
                        return value.length >= 6 && value.length <= 40;
                    }, translate('username-length')) && valid;
        valid =
                validate($form.find('#new_password'), validationRequired, translate('password-required')) &&
                validate($form.find('#new_password'), function(value) {
                    return value.length >= 8 && value.length <= 20;
                }, translate('password-length')) &&
                validate($form.find('#new_password'), function(value) {
                    return /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value) && /[^0-9a-zA-Z ]/.test(value);
                }, translate('password-complexity')) &&
                valid;
        valid = validate($form.find('#repeat_password'), validationRequired, translate('password-not-repeated')) &&
                validate($form.find('#repeat_password'), function(value) {
                    return $form.find('#repeat_password').val() == $form.find('#new_password').val();
                }, translate('password-not-equal')) &&
                valid;

        if(!valid) {
            showValidationMessage();
            return;
        }

        $.post('/account/register-account.json', {
            _token: user_data.csrf,
            id: $form.find('[name=donor_id]').val(),
            hash: $form.find('[name=hash]').val(),
            username: $form.find('#username').val(),
            new_password: $form.find('#new_password').val(),
            repeat_password: $form.find('#repeat_password').val()
        }, function(data) {
            if(data.success) {
                $form.hide();
                $('#register-account-send').removeClass('hidden');
            }
            else {
                resetValidationMessages();
                addError(data.error);
                showValidationMessage();
            }
        }, 'json')


    });

});
