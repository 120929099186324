import jQuery from "jquery";
import {isLoggedIn, onPageLoadAndSwap} from "./init-swup";
import {showModal} from "./components/modal";
import {languageIso} from "./translate";

let checkActivityRegistered = false;

function registerActivity() {
    let is_logged_in = true;
    let lastcheck = new Date();

    console.log('Registering activity checker');
    function checkActivity() {
        if(is_logged_in && (new Date()).getTime() - (15 * 60 * 1000) > lastcheck) {
            lastcheck = new Date();
            jQuery.get('/user/is-logged-in.json', function(data) {
                if(data.logged_in == false) {
                    is_logged_in = false;
                    console.log('Should show modal');
                    showModal('#sessionExpiredModal');

                    jQuery('body').click(function() {
                        document.location.href = '/' + languageIso() + '/login.html';
                    })
                }
                else {
                    // still logged in
                }
            }, 'json');
        }

    }

    jQuery('body').on('mousemove', checkActivity);
    jQuery('body').on('keypress', checkActivity);
}

onPageLoadAndSwap(function() {
    if(!checkActivityRegistered && isLoggedIn()) {
        checkActivityRegistered = true;
        registerActivity();
    }
});
