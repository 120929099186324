import $ from "jquery";
import {hideModal, showModal} from "@/front/components/modal";
import {onPageLoadAndSwap} from "@/front/init-swup";

let messages = [];

function validate($el, check, message) {
    let valid = check($el.val());

    if(!valid) {
        messages.push(message);
        let $wrapper = $el.closest('.dms-forms-field-wrapper');

        $wrapper.find('[data-error-classes]').each(function() {
            $(this).addClass($(this).data('error-classes'));
        });
        $wrapper.find('[data-valid-classes]').each(function() {
            $(this).removeClass($(this).data('valid-classes'));
        });

        $el.on('change', function() {
            if(check($el.val())) {

                $wrapper.find('[data-error-classes]').each(function() {
                    $(this).removeClass($(this).data('error-classes'));
                });
                $wrapper.find('[data-valid-classes]').each(function() {
                    $(this).addClass($(this).data('valid-classes'));
                });
            }
        });

    }
    return valid;
}

function addError(message) {
    messages.push(message);
}

function validationRequired(value) {
    return typeof value !== 'undefined' && value.length > 0;
}

function validationEmail(value) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return value.match(validRegex);
}

function resetValidationMessages() {
    messages = [];
}

function showValidationMessage() {
    let $list = $('#errorList');

    $list.html('');
    for(let i = 0; i < messages.length; i++) {
        $list.append('<li>' + messages[i] + '</li>');
    }

    showModal('#errorModal');
}

onPageLoadAndSwap(function($) {

    $('#errorModal .btn-ok').click(function() {
        hideModal('#errorModal');
    })

})

export { validate, validationRequired, validationEmail, resetValidationMessages, showValidationMessage, addError }
