import {onPageLoad, getUserData} from "@/front/init-swup";
import jQuery from "jquery";
import {resetValidationMessages, showValidationMessage, validate, validationRequired} from "@/front/validate";

let processed = false;
let bluesnap_encoder = null;
let threeDsToken = null;
let bluesnapKey = null;
let bluesnapIsSandbox = null;
let threeDsPaymentsSubmitCallback = function(reference) {}
let threeDsPaymentsFailedCallback = function(message) {}


onPageLoad(function($) {
    function updateToken() {
        if(typeof getUserData().bluesnap === 'undefined') {
            setTimeout(updateToken, 500);
            return;
        }
        updateBlueSnapToken(getUserData().bluesnap);
    }

    updateToken();
});

function getThreeDsToken() {
    return threeDsToken;
}

function updateBlueSnapToken(data) {
    console.log(data);
    if(data.success) {
        console.log('Setting up bluesnap token');
        threeDsToken = data.token;
        bluesnapKey = data.key;
        bluesnapIsSandbox = data.isSandbox;
        bluesnap_encoder = new BlueSnap(bluesnapKey, bluesnapIsSandbox);

        bluesnap.threeDsPaymentsSetup(threeDsToken, function(sdkResponse){
            console.log(sdkResponse);
            let code = sdkResponse.code;
            let reference = 0;
            if (code == 1){  // on Success
                var threeDSecure = sdkResponse.threeDSecure;
                // SUBMIT THE FORM HERE!

                reference = threeDSecure.threeDSecureReferenceId;
                threeDsPaymentsSubmitCallback(reference);
            } else {
                // in case some errors occurred
                var errorsArray = sdkResponse.info.errors;
                // in case some warnings occurred
                var warningsArray = sdkResponse.info.warnings;
                threeDsPaymentsFailedCallback(sdkResponse.info.errors);
            }

            // How are we going to resubmit the form here

        });
    }
}

function refreshThreeDsToken() {
    jQuery.get('/bluesnap/token.json', function(data) {
        updateBlueSnapToken(data);
    }, 'json')
}

function processThreeDSecure(ccNumber, secureCode, expiryMonth, expiryYear, amount, currency, callback, failedCallback) {
    let newCard = {
        "ccNumber": ccNumber,
        "cvv": secureCode,
        "expDate": expiryMonth + "/" + expiryYear,
        "amount": parseFloat(amount.replace(',', '.')),
        "currency": currency
    }

    threeDsPaymentsSubmitCallback = callback;
    threeDsPaymentsFailedCallback = failedCallback;

    bluesnap.threeDsPaymentsSubmitData(newCard);
    return false;
}

function encryptForm(formId, callback) {
    bluesnap_encoder.encrypt(formId);
    callback();
}

function processThreeDSecureAndEncrypt(ccNumber, secureCode, expiryMonth, expiryYear, amount, currency) {
    if(processed || (typeof $('select#method_key').val() != 'undefined')) {
        bluesnap_encoder.encrypt($(this).attr('id'));
        processed = false;
        return true;
    }

    var newCard = {
        "ccNumber": ccNumber,
        "cvv": secureCode.val(),
        "expDate": expiryMonth + "/" + expiryYear,
        "amount": parseFloat(amount.replace(',', '.')),
        "currency": currency
    }

    // console.log(threeDSecureObj);
    console.log(newCard);
    bluesnap.threeDsPaymentsSubmitData(newCard);
    return false;

}

export { getThreeDsToken, refreshThreeDsToken, processThreeDSecure, encryptForm, updateBlueSnapToken };
