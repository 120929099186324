import {onPageLoad} from "@/front/init-swup";

let siteKey = null;

onPageLoad(function($) {
    $.get('/google/captcha-v3/site-key.json', async function(data) {
        siteKey = data.siteKey;

    });


})

async function getCaptchaToken(action, callback) {

    return new Promise((res, rej) => {
        grecaptcha.ready(function () {
            grecaptcha.execute(siteKey, {action: action}).then(function (token) {
                if(callback === null || typeof callback === "undefined") {
                    return res(token);
                }
                else {
                    callback(token);
                }

            });
        });
    });
}

export {getCaptchaToken};
