import {onPageLoadAndSwap, onPageLoad, goToPage, user_data, updateUserData, onLogin} from '@/front/init-swup';

onPageLoadAndSwap(function($) {
    $('#login-form').submit(function(event) {
        event.preventDefault();

        let form = $(this);
        let link = $(this).attr('action');

        if(link == '') link = '/login/donate.html';

        form.find('.general-error').addClass('hidden');

        $.post('/user/login.json', {
            _token: user_data.csrf,
            username: form.find('#username').val(),
            password: form.find('#password').val()
        }, function(data) {
            if(typeof data.id == 'undefined') {
                form.find('.general-error').html(data.message);
                form.find('.general-error').removeClass('hidden');
            }
            else {
                updateUserData(data);
                onLogin();

                goToPage(link);
            }
        }, 'json');


    })
});


onPageLoad(function($) {
    $('body').on('click', '.button-logout', function(e) {
        e.stopPropagation();

        let link = $(this).attr('href');

        if(link == '') link = '/login.html';

        $.get('/user/logout.json', function(data) {
            updateUserData(data);
            goToPage(link);
        }, 'json')
    })
})
