import {onPageLoad} from "@/front/init-swup";
import $ from "jquery";
import {handlePayment} from "@/front/forms/donate";

let createTransaction = null;

function challenge(result, isSandbox) {
    // let form = $('<form method="POST" target="_top" action="' + result.url + '">');
    // form.append('<input type="hidden" name="PaReq" value="' + result.paRequest +'" />');
    // form.append('<input type="hidden" name="TermUrl" value="' + result.returnUrl +'" />');
    //
    // $('body').append(form);
    // form.submit();
}

function challenge2(result, isSandbox) {
    $('body').append('<iframe height="400" width="390" id="collectionFrame2" name="worldPayCollectionFrame"></iframe>');

    let url = "https://centinelapi.cardinalcommerce.com/V2/Cruise/StepUp";
    if(typeof isSandbox != 'undefined' && isSandbox) url = "https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp";
    var frame_content = '<html><body><form id="collectionForm" method="POST" action="' + url + '"><input type="hidden" name="JWT" value= "' + result.paRequest +'>" /><input type="submit" style="opacity: 0" /></form></body></html>';

    var frame = document.getElementById("collectionFrame2");
    var fdoc = frame.contentDocument;
    fdoc.write(frame_content);

    $(fdoc).find('form').submit();
}


let handleChallenge = function(result, isSandbox) {
    if(result.returnUrl !== null) {
        // 3DS 1
        challenge(result, isSandbox);
    }
    else {
        // 3DS 2
        challenge2(result, isSandbox);
    }
}

onPageLoad(function($) {
    window.addEventListener("message", function(event) {
        try {
            var data = JSON.parse(event.data);
            //This is a Cardinal Commerce URL in live.
            if (createTransaction !== null) {
                createTransaction(data.SessionId, handleChallenge);

                $('#collectionFrame').remove();
                $('#collectionForm').remove();
            }
        } catch (e) {

        }
    }, false);

    window.addEventListener("message", function(event) {
        try {
            console.log(event);

            if(event.origin != 'https://' + document.location.host) console.log('Invalid: `' + event.origin + '` should be `https://' + document.location.host + '`');
            console.log(document.location);
            if(event.source !== null && event.source.name) {
                switch(event.source.name) {
                    case 'worldPayCollectionFrame' :
                        $.get(event.data.url, function(data) {
                            console.log(data);
                            handlePayment(data)
                        }, 'json');
                        $('#collectionFrame2').remove();
                        break;
                }
            }
        } catch (e) {

        }
    }, false);
});

function jwtPostUrl(isSandbox) {
    if(isSandbox) return 'https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect';
    return 'https://centinelapi.cardinalcommerce.com/V1/Cruise/Collect';
}

function get_jwt(isSandbox, creditcard) {
    $.get('/worldpay/token.json', function(data) {
        // let iframe = $('<iframe id="collectionFrame" height="1" width="1" style="display: none;" />');
        let iframe = $('<iframe id="collectionFrame" height="1000" width="1000" style="" />');

        let frame_body = $('<html />');

        frame_body.append('<form id="collectionForm" method="POST" action="' + jwtPostUrl(isSandbox) + '">\n' +
            '            <input type="text" name="Bin" value="' + creditcard + '" />\n' +
            '            <input type="text" name="JWT" value="' + data.jwt + '" />\n' +
            '            <input type="submit" />\n' +
            '        </form>');

        let frame_content = '<html><head>' +
            '<script> function submitForm() { document.getElementById("collectionForm").submit(); } </script>' +
            '</head><body onload="setTimeout(submitForm, 500);">' + frame_body.html() + '</body></html>';

        $('body').append(iframe);

        let frame = document.getElementById("collectionFrame");
        let fdoc = frame.contentDocument;
        fdoc.write(frame_content);

        $(fdoc).find('form').submit();

    }, "json");
}

function setUpThreeDSecure(data, rule, callback) {
    createTransaction = callback;

    get_jwt(rule.merchantSettings.isSandbox, data.transaction.creditcard);
}


export {setUpThreeDSecure};
