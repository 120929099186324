import {onPageLoad, onPageLoadAndSwap} from "@/front/init-swup";

let data = null;
let country = "";
let state = "";
let tag = "";
let event = "NO";

function setEvent(pv_event) {
    switch(pv_event) {
        case 'yes' :
        case 1 :
        case 'YES' :
            event = 'YES';
            break;
        default :
            event = 'NO';
            break;
    }
    console.log('Event set to ' + event);
}

function getEvent() {
    return event;
}

onPageLoadAndSwap(function($) {

    if(document.location.hash.length > 0) {
        data = document.location.hash.substring(1);
    }

    if(data !== null && data.length > 0) {
        const searchParams = new URLSearchParams(data);

        for (const p of searchParams) {
            console.log(p);
            let name = p[0];
            let value = p[1];

            switch(name) {
                case 'tag' :
                    tag = value;
                    break;
                case 'event' :
                    setEvent(value);
                    break;
                case 'state' :
                case 'states_code' :
                    state = value;
                    $('select[name=state]').val(value);
                    break;
                case 'recurring' :
                    if(value == 'yes') {
                        $('input[name=donation_type][value=RECURRING]').attr('checked', true);
                        $('.has-next-donation-selection').removeClass('hidden');
                    }
                    if(value == 'no') {
                        $('input[name=donation_type][value=SINGLE]').attr('checked', true);
                        $('.has-next-donation-selection').addClass('hidden');
                    }
                    break;
                case 'title' :
                    switch(value) {
                        case 'Mr.' :
                            $('input[name=title][value=Mr\\.]').attr('checked', true);
                            break;
                        case 'Mrs.' :
                            $('input[name=title][value=Mrs\\.]').attr('checked', true);
                            break;
                        case 'Ms.' :
                            $('input[name=title][value=Ms\\.]').attr('checked', true);
                            break;
                        case 'Miss' :
                            $('input[name=title][value=' + value + ']').attr('checked', true);
                            break;
                    }
                    break;
                case 'project_id' :
                    $("select[name=project]").val(value);
                    break;
                case 'project' :
                case 'currency' :
                case 'recurring_day' :
                    $("select[name=" + name + "]").val(value);

                    break;
                case 'country_iso' :
                    country = value;
                    $("select[name=" + name + "]").val(value);
                    break;
                case 'firstname' :
                case 'insertion' :
                case 'lastname' :
                case 'street' :
                case 'phone' :
                case 'email' :
                case 'address_line1' :
                case 'zipcode' :
                case 'city' :
                case 'amount' :
                    $("input[name=" + name + "]").val(value);
                    break;
            }
        }
    }
});

function getCountryDefault() {
    return country;
}

function getStateDefault() {
    return state;
}

function getTag() {
    return tag;
}


export { getStateDefault, getCountryDefault, getEvent, getTag }
