import {onPageLoadAndSwap, user_data} from "@/front/init-swup";
import {resetValidationMessages, showValidationMessage, validate, validationRequired} from "@/front/validate";
import {translate} from "../translate";


onPageLoadAndSwap(function($) {

    $('#forgot-password').submit(function(event) {
        event.preventDefault();

        let $form = $(this);
        let valid = true;

        resetValidationMessages();

        valid = validate($form.find('#username'), validationRequired, translate('enter-username')) && valid;

        if(!valid) {
            showValidationMessage();
            return;
        }

        $.post('/user/forgot-password.json', {
            _token: user_data.csrf,
            username: $form.find('#username').val()
        }, function() {}, 'json');
        $form.hide();
        $('#forgot-password-form-send').removeClass('hidden');

    });

})
