import {onPageLoad} from "@/front/init-swup";

onPageLoad(function($) {

    $(document).click(function (event) {
        if($('div.mobile-nav').data('open') == true) {
            $('div.mobile-nav').slideUp();
            $('div.mobile-nav').data('open', false);
        }
    });

    $('body').on('click', '[data-toggle]', function () {
        let togglePath = $(this).data('toggle');

        switch($(this).data('toggle-transition')) {
            case 'slide' :
                $(togglePath).data('open', false);
                $(togglePath).slideToggle(function() {
                    $(togglePath).data('open', true);
                });
                break;
            default :
                $(togglePath).toggle();
                break;
        }
    });
});
