import {onPageLoadAndSwap, user_data, navigateTo, loadDonations, loadRecurrings, onPageLoad} from "@/front/init-swup";
import { validate, validationRequired, validationEmail, resetValidationMessages, showValidationMessage, addError} from '@/front/validate';
import {hideModal, showModal} from "@/front/components/modal";
import {encryptForm, processThreeDSecure} from "@/front/bluesnap";
import {disableButton, enableButton} from "@/front/components/button";
import Bugsnag from "@bugsnag/js";
import {Exception} from "sass";
import {setUpThreeDSecure} from "@/front/worldpay";
import {languageIso, translate} from "@/front/translate";
import {getCaptchaToken} from "@/3rdparty/google/recaptcha3";
import jQuery from "jquery";
import {getEvent, getTag} from "./pre-load-data";

let last_result = null;
let payment_rules = null;

onPageLoad(function($) {
    $.get('/payment/rules.json', function(data) {
        payment_rules = data;
    }, 'json');
});

function handleSuccesfullPayment(result) {
    last_result = result;
    navigateTo(result.url);
    loadDonations();
    loadRecurrings();

}

function handleFailedPayment(result, $submit) {
    if(typeof result.error != 'undefined' && result.error.length > 0) {
        addError(result.error);
    }
    else {
        addError('An unknown error occurred(1).');
    }
    showValidationMessage();
    enableButton(jQuery('#donate-form').find('button[type=submit]'));

}

function handlePayment(result) {
    if(result.success) {
        handleSuccesfullPayment(result);
    }
    else {
        handleFailedPayment(result);
    }
}

function parseCardType(creditcard) {
    if(creditcard.substring(0,1) == '4') return 'VISA';
    if(creditcard.substring(0,1) == '5') return 'MASTERCARD';
    if(creditcard.substring(0,2) == '34') return 'AMERICAN_EXPRESS';
    if(creditcard.substring(0,2) == '37') return 'AMERICAN_EXPRESS';
    if(creditcard.substring(0,1) == '6') return 'DISCOVER';

    return 'DINERS';
}

function ruleIsValid(rule, data) {
    if(rule.currency !== null && rule.currency != data.transaction.currency) return false;
    if(rule.cardType !== null && rule.cardType != parseCardType(data.transaction.creditcard)) return false;
    if(rule.recurring !== 'BOTH' && rule.recurring === 'YES' && data.transaction.paymentType == 'SINGLE') return false;
    if(rule.recurring !== 'BOTH' && rule.recurring === 'NO' && data.transaction.paymentType == 'RECURRING') return false;
    if(rule.recurring !== 'BOTH' && rule.recurring === 'NO' && data.transaction.paymentType == 'INCREASE') return false;
    if(rule.event !== 'BOTH' && rule.event == 'YES' && data.transaction.event == false) return false;
    if(rule.event !== 'BOTH' && rule.event == 'NO' && data.transaction.event == true) return false;
    if(rule.projectId !== null && rule.projectId != data.transaction.project) return false;

    return true;
}

function getPaymentMethod(data) {
    for(let i = 0; i < payment_rules.length; i++) {
        if(ruleIsValid(payment_rules[i], data)) {
            return payment_rules[i];
        }
    }
    return null;
}

function validateDonorInfo($form) {
    let valid = true;

    valid = validate($form.find('#firstname'), validationRequired, translate('firstname-required')) && valid;
    valid = validate($form.find('#lastname'), validationRequired, translate('lastname-required')) && valid;
    valid = validate($form.find('#street'), validationRequired, translate('street-required')) && valid;
    valid = validate($form.find('#zipcode'), validationRequired, translate('zipcode-required')) && valid;
    valid = validate($form.find('#city'), validationRequired, translate('city-required')) && valid;
    valid = validate($form.find('#country'), validationRequired, translate('country-required')) && valid;
    // valid = validate($form.find('#phone'), validationRequired, translate('phone-required')) && valid;
    valid = validate($form.find('#email'), validationRequired, translate('email-required')) &&
            validate($form.find('#email'), validationEmail, translate('email-not-valid')) && valid;

    return valid;
}

function validateConsentAgreement($form) {
    if($form.find('#consent').is(':visible')) {
        return validate($form.find('#consent'), function(value) {
                return $form.find('#consent').is(':checked');
            }, translate('agree-with-consent-agreement'));
    }
    return true;
}

function validateFirstPaymentImmediate($form) {
    if($form.find('#first-donation').is(':visible')) {
        return validate($form.find('#first-donation'), function(value) {
                return $form.find('#first-donation').is(':checked');
            }, translate('confirm-first-donation'));
    }
    return true;
}

function validateTransactionInfo($form) {
    let valid = true;

    valid = validate($form.find('.group_donation_type'), function(value) {
                return (typeof $form.find('.group_donation_type input:checked').val() != 'undefined')
            }, translate('select-donation-type')) && valid;

    valid = validate($form.find('#amount'), function(value) { return value.match(/^[0-9]+[,\.]?[0-9]{0,2}$/); }, translate('amount-please-use-format')) &&
            validate($form.find('#amount'), function(value) { return !(parseFloat(value) < 5); }, translate('process-fees-donation-minimum')) && valid;

    valid = validate($form.find('#currency'), validationRequired, translate('currency-not-valid')) && valid;

    if($form.find('input[name=payment_method]').length > 0 && $form.find('input[name=payment_method]:checked').val() === 'ach') {
        valid = validate($form.find('#ach_type'), validationRequired, translate('select-account-type')) && valid;
        valid = validate($form.find('#routing_nr'), validationRequired, translate('enter-routing-number')) && valid;
        valid = validate($form.find('#account_nr'), validationRequired, translate('enter-account-number')) && valid;
    }
    else {
        valid = validate($form.find('#cc_number'), validationRequired, translate('enter-creditcard-number')) && valid;
        valid = validate($form.find('#cvc'), validationRequired, translate('enter-cvc')) && valid;
        valid = validate($form.find('#expiry_month'), validationRequired, translate('enter-expiry-month')) && valid;
        valid = validate($form.find('#expiry_year'), validationRequired, translate('enter-expiry-year')) && valid;
    }

    valid = validateConsentAgreement($form) && valid;
    valid = validateFirstPaymentImmediate($form) && valid;

    return valid;
}


function validateInMemoryOfInfo($form) {
    if($form.find('#imo_firstname').length == 0) return true;

    let valid = true;

    valid = validate($form.find('#imo_firstname'), validationRequired, translate('imo-firstname-required')) && valid;
    valid = validate($form.find('#imo_lastname'), validationRequired, translate('imo-lastname-required')) && valid;
    valid = validate($form.find('[name=imo_anonymous]'), function() {
        return $form.find('[name=imo_anonymous]:checked').is(':checked');
    }, translate('imo-anonymous-required')) && valid;

    if($form.find('[name=imo_anonymous]:checked').val() == "0") {
        valid = validate($form.find('#family_contact'), validationRequired, translate('imo-family-contact-required')) && valid;
        valid = validate($form.find('#family_email'), validationRequired, translate('imo-family-email-required')) && valid;
    }

    return valid;
}

function collectDonorInfo($form) {
    return {
        title: $form.find('[name=title]:checked').val(),
        firstname: $form.find('#firstname').val(),
        insertion: $form.find('#insertion').val(),
        lastname: $form.find('#lastname').val(),
        street: $form.find('#street').val(),
        address_line1: $form.find('#address_line1').val(),
        zipcode: $form.find('#zipcode').val(),
        city: $form.find('#city').val(),
        country: $form.find('#country').val(),
        state: $form.find('#state').val(),
        phone: $form.find('#phone').val(),
        email: $form.find('#email').val(),
    }
}

function collectInMemoryOfInfo($form) {
    return {
        firstname: $form.find('#imo_firstname').val(),
        lastname: $form.find('#imo_lastname').val(),
        note: $form.find('#imo_note').val(),
        anonymous: $form.find('[name=imo_anonymous]:checked').val(),
        family_contact: $form.find('#family_contact').val(),
        family_email: $form.find('#family_email').val(),
        family_phone: $form.find('#family_phone').val(),
    }
}

function collectTransactionInfo($form) {
    return {
        paymentType: $form.find('[name=donation_type]:checked').val(),
        recurring_day: $form.find('#recurring_day').val(),
        project: $form.find('#project').val(),
        amount: $form.find('#amount').val(),
        currency: $form.find('#currency').val(),
        creditcard: $form.find('#cc_number').val(),
        cvc: $form.find('#cvc').val(),
        expiry_month: $form.find('#expiry_month').val(),
        expiry_year: $form.find('#expiry_year').val(),
        threeDsToken: null,
        event: getEvent(),
        tag: getTag(),
        fraudProtection: $form.find('#fraudProtection').val(),
        card_type: parseCardType($form.find('#cc_number').val()),
        payment_method: $form.find('[name=payment_method]:checked').val(),
        ach_type: $form.find('#ach_type').val(),
        routing_nr: $form.find('#routing_nr').val(),
        account_nr: $form.find('#account_nr').val(),
    };
}

onPageLoadAndSwap(function($) {
    function checkRecurring() {
        if($(this).is(':checked')) {
            $('.visible-for-recurring').removeClass('hidden');
        }
        else {
            $('.visible-for-recurring').addClass('hidden');
        }
    }

    $('input[name=recurring]').change(checkRecurring)
    checkRecurring();
});

onPageLoadAndSwap(function($) {
    let $methods = $('input[name=payment_method]');
    function setMethodForm() {
        let value = $('input[name=payment_method]:checked').val();

        $('div.method').hide();
        $('div.method_' + value).show();
    }


    if($methods.length > 0) {
        $methods.click(setMethodForm);
        setMethodForm();
    }


})

onPageLoadAndSwap(function($) {
    if(last_result !== null) {
        Object.keys(last_result.transaction).forEach(key => {
            console.log(key, last_result[key]);
            $('.latest-text-' + key).html(last_result.transaction[key]);
        });
    }
})

onPageLoadAndSwap(function($) {

    $('input[name=donation_type]').click(function() {
        switch($(this).val()) {
            case 'SINGLE' :
                $('.has-next-donation-selection').addClass('hidden');
                break;
            case 'RECURRING' :
                $('.has-next-donation-selection').removeClass('hidden');
                showModal('#recurring_notice');

                break;
            case 'INCREASE' :
                $('.has-next-donation-selection').removeClass('hidden');
                showModal('#increase_notice');
                break;
        }
    });

    $('.has-next-donation-selection select').change(function() {
        $('.has-next-donation-selection select').val($(this).val());
    });

    $('#recurring_notice .btn-ok').click(function() {
        hideModal('#recurring_notice');
    });

    $('#increase_notice .btn-ok').click(function() {
        hideModal('#increase_notice');
    });

    $('#recurring_notice .btn-cancel').click(function() {
        $('input[name=donation_type][value=SINGLE]').click();
    });

    $('#increase_notice .btn-cancel').click(function() {
        $('input[name=donation_type][value=SINGLE]').click();
    });

    $('#donate-form').submit(async function(event) {
        event.preventDefault();

        let $form = $(this);
        let $submit = $form.find('button[type=submit]');
        let isPublic = $form.hasClass('is-public');

        let data = {
            _token: user_data.csrf,
            _captchav3: await getCaptchaToken('donate'),
            locale: languageIso(),
            donor: null,
            transaction: null
        };

        $form.find('.general-error').addClass('hidden');
        $form.find('.general-success').addClass('hidden');

        disableButton($submit);

        resetValidationMessages();

        let valid = true;
        if(isPublic) valid = validateDonorInfo($form) && valid;
        valid = validateTransactionInfo($form) && valid;
        valid = validateInMemoryOfInfo($form) && valid;

        if(!valid) {
            showValidationMessage();
            enableButton($submit);
            return false;
        }

        if(isPublic) data.donor = collectDonorInfo($form);
        data.imo = collectInMemoryOfInfo($form);
        data.transaction = collectTransactionInfo($form);

        console.log(data);

        let createTransaction = function(handleChallenge) {
            disableButton($submit);
            $.ajax('/donations/create.json', {
                async: true,
                method: 'POST',
                data: data,
                dataType: 'json',
                success: function(result) {
                    console.log(result);
                    if(result.challenge && typeof handleChallenge !== 'undefined') {
                        handleChallenge(result, rule.merchantSettings.isSandbox);
                    }
                    else {
                        handlePayment(result);
                    }

                },
                error: function() {
                    addError('An unknown error occurred(2).');
                    showValidationMessage();
                    enableButton($submit);
                }

            });
        }

        let rule = getPaymentMethod(data);
        console.log(rule);

        if(rule.merchantSettings.threeDsEnabled) {
            switch(rule.merchantType) {
                case 'bluepay' :
                    createTransaction();
                    break;
                case 'amex' :
                case 'worldpay' :
                case 'worldpay_recurring' :
                    if(rule.merchantSettings.threeDsEnabled) setUpThreeDSecure(data, rule, function(sessionId, handleChallenge) {
                        data.transaction.threeDsToken = sessionId;
                        createTransaction(handleChallenge, rule.merchantSettings.isSandbox);
                    });
                    else {
                        console.log('Hello world');
                        createTransaction();
                    }
                    break;
                case 'bluesnap' :
                    processThreeDSecure(
                        $form.find('#cc_number').val(),
                        $form.find('#cvc').val(),
                        data.transaction.expiry_month,
                        data.transaction.expiry_year,
                        data.transaction.amount,
                        data.transaction.currency,
                        function(reference) {
                            data.transaction.threeDsToken = reference;

                            $form.find('#cc_number').attr('data-bluesnap', 'encryptedCreditCard');
                            $form.find('#cvc').attr('data-bluesnap', 'encryptedCvv');
                            $form.find('#expiry_month').attr('data-bluesnap', 'expirationMonth');
                            $form.find('#expiry_year').attr('data-bluesnap', 'expirationYear');

                            encryptForm($form.attr('id'), function() {
                                data.transaction.creditcard = $form.find('[name=encryptedCreditCard]').val();
                                data.transaction.cvc = $form.find('[name=encryptedCvv]').val();
                                createTransaction();
                            });
                        },
                        function(messages) {
                            for(let i = 0; i < messages.length; i++) {
                                switch(messages[i]) {
                                    case 'Invalid ccNumber' :
                                        addError('Your credit card number is invalid');
                                        break;
                                    case 'Invalid expDate' :
                                        addError('The expiry date is invalid');
                                        break;
                                    case '3D Secure authentication failed' :
                                        addError('3D Secure authentication failed');
                                        break;
                                    default :
                                        Bugsnag.notify(new Error('BlueSnap mesagge (' + messages[i] + ')'));
                                        addError(messages[i]);
                                }

                            }
                            showValidationMessage();
                            enableButton($submit);
                        });
                    break;
                default :
                    break;
            }
        }
        else {
            createTransaction();
        }

    });

    $(".open-consent").click(function(event) {
        event.preventDefault();

        $('.form-data-amount').text($('#amount').val());
        $('.form-data-currency').text($('#currency').val());

        let ccNumber = $('#cc_number').val();
        let lastDigits = ccNumber.substring(ccNumber.length - 4, ccNumber.length);

        $('.form-data-creditcard-ending').text(lastDigits);

        showModal('#consentModal');
    });

    $('#consentModal .btn-ok').click(function() {
        hideModal('#consentModal');
    })
})

export { handleSuccesfullPayment, handleFailedPayment, handlePayment };
