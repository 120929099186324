import jQuery from "jquery";
import {onPageLoad, onPageLoadAndSwap} from "./init-swup";
import {hideModal, showModal} from "@/front/components/modal";
import $ from "jquery";

let link  = window.location.hash;

onPageLoadAndSwap(function() {
    let modal = jQuery('#unitedStatesModal');

    if (modal.length > 0) {
        let fullUrl = modal.find('#wopiLink').attr('href') + '#' + link.substring(1) + '&currency=USD';
        if(link.length > 2) modal.find('#wopiLink').attr('href', modal.find('#wopiLink').attr('href') + '#' + link.substring(1))

        let url = new URL(fullUrl);

        if(url.searchParams.get('wopitarget') === '_blank') {
            modal.find('#wopiLink').attr('target', url.searchParams.get('wopitarget'));
        }

        // url.searchParams.set('currency', 'USD');


        modal.find('#wopiLink').attr('href', url.toString());
        showModal('#unitedStatesModal');

        $('#close-button').click(function(e) {
            hideModal('#unitedStatesModal')
        });
    }



});

