import {onPageLoad, onPageSwap, user_data} from "@/front/init-swup";
import {getCountryDefault, getStateDefault} from "@/front/forms/pre-load-data";

onPageLoad(function($) {
    let countries = null;

    function hideContainer(container) {
        container.addClass('hidden');
    }

    function showContainer(container) {
        container.removeClass('hidden');
    }

    function updateStateSelect(iso, state, country, container) {
        let found = false;
        let placeholder = state.data('placeholder');

        for(let i = 0; i < countries.length; i++) {
            if(countries[i].iso == iso && countries[i].states.length > 0) {
                found = true;

                if(placeholder.length) {
                    state.html('<option value="">' + state.data('placeholder') + '</option>');
                }
                else {
                    state.html('');
                }

                for(let j = 0; j < countries[i].states.length; j++) {
                    let code = countries[i].states[j].code;
                    let name = countries[i].states[j].name;
                    state.append('<option value="' + code + '">' + name + '</option>');
                }


                if(typeof user_data.id != 'undefined') {
                    state.val(user_data.address.state_code);
                }

                showContainer(container);

                if(getStateDefault().length > 0) {
                    state.val(getStateDefault());
                }

            }
        }

        if(!found) {
            state.html('');
            hideContainer(container);
        }
    }

    function init() {
        $('.form-select-state').each(function() {
            let container = $(this);
            let state = $(this).find('select');
            let country = $(container.data('country'));

            country.change(function() {
                updateStateSelect(country.val(), state, country, container);
            });

            if(typeof user_data.id != 'undefined') {
                country.val(user_data.address.country_iso);
            }
            else {
                if(getCountryDefault().length > 0) {
                    country.val(getCountryDefault());
                }
            }
            updateStateSelect(country.val(), state, country, container);
        });
    }

    onPageSwap(init);

    $.get('/data/countries.json', function(data) {
        countries = data.countries;
        init();
    });
})
